import { Link } from 'gatsby'
import Button from 'src/components/ui/Button'
import { Image } from 'src/components/ui/Image'
import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import '../styles.scss'

const CartaoPresente = () => {
  return (
    <>
      <section className="section-services gift-card_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar CartaoPresente-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- CartaoPresente Products-> */}
              <div className="services-content px-3">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center flex items-center justify-center">
                    CARTÃO PRESENTE
                  </h1>
                  <Button className="bg-blue justify-center text-white text-xs my-0 m-auto flex items-center h-[30px] max-w-[234px] w-full hover:bg-darkBlue transition-all rounded-sm">
                    <a
                      className="consult-button text-xs font-bold"
                      href="https://wwws-usa1.givex.com/Merchant_pages/14697/"
                      target="_blank"
                      onClick={() =>
                        window.open(
                          'https://wwws-usa1.givex.com/Merchant_pages/14697/',
                          '',
                          'Location =no,status=no, scrollbars=no, width=270, height=440, resizable=no,top=0, left=30,scrollbars=no, directories=no,titlebar=no, menubar=no, maximized=no'
                        )
                      }
                      rel="noreferrer"
                    >
                      CONSULTAR SALDO DISPONÍVEL
                    </a>
                  </Button>
                  <p className="text-xs !text-shadeOfGray text-center mt-3">
                    Aqui você pode consultar seu saldo do cartão.
                  </p>
                  <p className="text-left text-sm mt-4">
                    Nossos cartões presentes podem ser utilizados tanto no site
                    quanto nas lojas físicas Decathlon em todo país. No momento
                    não é possível utilizar o cartão presente no site de vendas
                    corporativas e pelo aplicativo Decathlon.
                  </p>
                  <p className="text-left text-sm mt-4">
                    O cartão presente tem validade de 12 meses, a partir da data
                    de ativação, e dentro de sua validade pode ser utilizado em
                    múltiplas compras. Em uma compra que o valor total exceda o
                    crédito em seu cartão presente, ele terá todo o seu saldo
                    consumido e o valor complementar poderá ser quitado com as
                    outras formas de pagamento disponíveis. Caso não seja
                    utilizado até o prazo descrito, o cartão presente é
                    automaticamente cancelado, não tendo como utilizar o saldo
                    que havia pendente e não haverá, em nenhuma hipótese,
                    restituição do valor.
                  </p>
                  <p className="text-left text-sm mt-4">
                    O cartão presente é de uso do portador e não nominal
                    portanto a Decathlon Brasil não se responsabiliza por perda,
                    roubo ou perda que resulte em sua inutilização. Para uma
                    mesma compra é possível utilizar até 5 cartões presentes e
                    não fazemos conversão de múltiplos cartões presentes em um
                    único.
                  </p>
                  <p className="text-left text-sm mt-4">
                    Em caso de dúvidas e/ou auxílio, poderá entrar em contato
                    diretamente com a nossa{' '}
                    <Link className="text-blue" to="/atendimento">
                      Central de Atendimento
                    </Link>{' '}
                    .
                  </p>
                </div>
                <div className="hro">
                  <h2 className="text-gray italic text-lg font-semibold text-left">
                    CARTÃO FÍSICO
                  </h2>
                  <p className="text-left text-sm mt-4">
                    O cartão presente físico é aquele adquirido em qualquer uma
                    de nossas lojas no Brasil. Quem o compra pode escolher entre
                    R$10,00 a R$1.000,00 o valor que carregará. O número do
                    cartão presente está em seu verso e contém 19 dígitos. Não
                    haverá, em nenhuma hipótese, restituição do valor ou
                    cancelamento do cartão presente gerado em lojas físicas.
                  </p>
                </div>
                <div className="hro">
                  <h2 className="text-gray italic text-lg font-semibold text-left">
                    VERSÃO DIGITAL
                  </h2>
                  <p className="text-left text-sm mt-4">
                    O cartão presente digital é aquele adquirido em nosso site
                    de vendas corporativas. Pode acontecer que, por virtude
                    disso, a imagem do cartão presente estar com o logo da
                    empresa que fez a aquisição e não o da Decathlon. Ele tem
                    que conter 19 dígitos em seu código de barras. Caso não
                    possua ou apresente algum erro no momento da realização da
                    compra, deve-se entrar em contato diretamente com a empresa
                    que realizou a compra ou a troca dos pontos.
                  </p>
                  <p className="text-left text-sm mt-4">
                    Para a aquisição, pode-se determinar a quantidade de cartões
                    necessários e o valor que constará em cada um. Deverá estar
                    compreendido entre R$20,00 a R$20.000,00 com todas as taxas
                    inclusas.
                  </p>
                  <p className="text-left text-sm mt-4">
                    Para cancelamento do cartão adquirido por parceiros, o
                    cliente deve entrar em contato direto com a plataforma em
                    que foi adquirido. Se comprado pelo site da DecathlonPro, o
                    mesmo deve entrar em contato com a Central de Atendimento.
                    Só poderão ser cancelados os cartões presentes ativados em
                    até 7 dias corridos e sem nenhuma utilização.
                  </p>
                </div>
                <div className="hro">
                  <h2 className="text-gray italic text-lg font-semibold text-left">
                    CARTÕES VENDIDOS EM POSTOS EXTERNOS
                  </h2>
                  <p className="text-left text-sm mt-4">
                    Temos cartões presentes disponíveis para vendas em alguns
                    supermercados/varejistas pelo Brasil. Quem o compra pode
                    escolher o valor que colocará entre R$50,00 a R$300,00. O
                    número do cartão presente está em seu verso e contém 19
                    dígitos junto com demais instruções de utilização. Não
                    haverá, em nenhuma hipótese, restituição do valor ou
                    cancelamento do cartão presente desta modalidade.
                  </p>
                </div>
                <div className="hro">
                  <h2 className="text-gray italic text-lg font-semibold text-left">
                    COMO UTILIZAR O VALE NO SITE?
                  </h2>
                  <p className="text-left text-sm mt-4">
                    A numeração do vale-troca ou vale-presente só será
                    necessária <strong> no momento do pagamento</strong>, após o
                    preenchimento de todos os seus dados pessoais (ou login) e
                    dados do local de entrega/retirada do pedido, como mostrado
                    no exemplo abaixo:
                  </p>
                  <Image
                    src="https://decathlonproqa.vtexassets.com/arquivos/vale-presente.png"
                    alt="vale-presente"
                    width={526}
                    height={226}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CartaoPresente
